<template>
  <div class="about">
    <!-- <s-header :hasBack="false" :isMore="true" :moreAction="moreAction" @moreback="moreback" /> -->
    <s-header />
    <n-bar :userType="'principal'" :activeItemName="'index'" />
    <support />

    <div class="pageContent">
      {{ userInfo.realName }}
    </div>
  </div>
</template>

<script>
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import { getLocal } from "@/common/js/utils";
export default {
  components: {
    sHeader,
    nBar,
    support,
  },
  setup() {
    // const supportList = []

    const userInfo = JSON.parse(getLocal("userInfo"));

    // const moreAction = []
    // const moreback = (item) => {
    // 	console.log(item);
    // }

    return {
      // supportList,
      userInfo,
      // moreAction,
      // moreback
    };
  },
};
</script>

<style lang="less" scoped>
@import "@/common/style/base.less";
</style>
